export function waitCallbackTruthy (callback) {
  return new Promise(resolve => {
    function check () {
      const result = callback();
      if (result) {
        resolve(result);
      } else {
        requestAnimationFrame(check);
      }
    }
    check();
  });
}
