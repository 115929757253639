<template>
  <div class="text-answer">
    <h4
      :class="[
        body.description ? 'mb-3' : '',
        heading ? 'question-title h2' : 'label-text',
      ]"
    >
      {{ body.question }}
    </h4>
    <p class="sub-text" v-if="body.description">{{ body.description }}</p>
    <p class="sub-text" v-if="body.header">{{ body.header }}</p>
    <b-form-input
      :style="{ height: textHeight }"
      id="textarea"
      v-model="answer"
      :placeholder="body.placeholder"
      :class="{ 'onboarding-textarea': isAshComputed, 'fs-mask': isSensitive }"
      rows="4"
      max-rows="6"
    >
    </b-form-input>
    <b-button
      variant="info"
      pill
      block
      class="mt-3 slight-rounded"
      @click="checkAnswer"
      :disabled="disabled"
      >Continue</b-button
    >
  </div>
</template>

<script>
export default {
  props: {
    body: {
      type: Object,
    },
    composite: {
      default: false,
      type: Boolean,
    },
    isAsh: {
      default: "true",
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: Boolean,
      default: false,
    },
    textHeight: {
      type: String,
    },
  },
  data() {
    return {
      answer: "",
    };
  },
  computed: {
    isAshComputed() {
      return this.isAsh == "false" ? false : true;
    },
    isSensitive() {
      return this.body.hasOwnProperty("sensitive") && this.body.sensitive;
    },
  },
  methods: {
    checkAnswer() {
      if (
        this.answer.replace(/ /g, "") == "" &&
        (!this.body.hasOwnProperty("allowempty") ||
          this.body.allowempty == false)
      ) {
        this.$emit("alert", "The text field cannot be empty.");
      } else {
        this.$emit("submitAnswer", this.body.id, this.answer, this.body);
      }
    },
  },
  mounted() {
    if (this.body.hasOwnProperty("answer")) {
      this.answer = this.body.answer;
    }
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-weight: normal !important;
}
</style>
